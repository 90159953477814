import Strapi from "strapi-sdk-js";

export default class Params {
    static API_URL = "https://api.lunay-handball.fr";

    static strapi = new Strapi({
        url: this.API_URL,
        prefix: "/api",
        store: {
          key: "strapi_jwt",
          useLocalStorage: false,
          cookieOptions: { path: "/" },
        },
        axiosOptions: {},
      });
} 