import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";

import '../styles/components/header.css';

import logo from '../assets/images/logo.png';
import SponsorSlider from './SponsorSlider';

const Header = () => {

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      setTimeout(() => {
        document.body.style.overflow = "visible";
      }, 500);
      
    }
  }, [showMenu])
  
  return (
    <header className="main-header">
      <img src={logo} alt="logo" className="logo" />
      <button id="burger-link" onClick={() => setShowMenu(!showMenu)} aria-expanded={showMenu} aria-controls="main-nav" aria-label="Menu" className={showMenu ? "opened" : ""}>
        <svg viewBox="0 0 100 100" aria-hidden="true">
          <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
          <path className="line line2" d="M 20,50 H 80" />
          <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
        </svg>
      </button>
      <nav className={"main-nav " + (showMenu ? "open" : "")}  aria-label="Menu">
        <ul className="nav-list">
          <li className="nav-item">
              <Link to='/' className='nav-link' onClick={() => setShowMenu(false)} tabIndex={!showMenu && "-1"}>Accueil</Link>
          </li>
          <li className="nav-item">
              <Link to={`news`} className='nav-link' onClick={() => setShowMenu(false)} tabIndex={!showMenu && "-1"}>Actualités</Link>
          </li>
          <li className="nav-item">
              <Link to={`teams`} className='nav-link' onClick={() => setShowMenu(false)} tabIndex={!showMenu && "-1"}>Equipes</Link>
          </li>
          <li className="nav-item">
              <Link to={`sponsors`} className='nav-link' onClick={() => setShowMenu(false)} tabIndex={!showMenu && "-1"}>Partenaires</Link>
          </li>
          <li className="nav-item">
              <Link to={`contact`} className='nav-link' onClick={() => setShowMenu(false)} tabIndex={!showMenu && "-1"}>Informations pratiques</Link>
          </li>
          <li className="nav-item">
              Ils nous soutiennent
          </li>
        </ul>

        <SponsorSlider />
        
      </nav>
    </header>
  )
}

export default Header