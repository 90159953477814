import React from 'react'
import Params from '../utils/params';

const Sponsor = (props) => {
    const { sponsor } = props;
    console.log(sponsor);
    return (
      <article>
        {/* <h2>{sponsor.name}</h2> */}
        <a href={sponsor.url} target='_blank'>
          <picture>
              { sponsor.logo.formats.small &&  <source srcSet={Params.API_URL+sponsor.logo.formats.small.url} media="(width < 600px)"  /> }
              { sponsor.logo.formats.medium && <source srcSet={Params.API_URL+sponsor.logo.formats.medium.url} media="(width < 800px)"  /> }
              <img src={Params.API_URL+sponsor.logo.url}  alt="Logo du sponsor"/>
          </picture>
        </a>
      </article>
    )
}

export default Sponsor