import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, createBrowserRouter, RouterProvider, createRoutesFromElements } from "react-router-dom";

import App from './App';

import Error404 from './pages/Error404';
import Home from './pages/Home';

import './index.css';

import reportWebVitals from './reportWebVitals';
import Teams from './pages/Teams';
import News from './pages/News';
import Sponsors from './pages/Sponsors';
import Contact from './pages/Contact';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="" element={<Home />} />
      <Route path="news" element={<News />} />
      <Route path="teams" element={<Teams />} />
      <Route path="sponsors" element={<Sponsors />} />
      <Route path="contact" element={<Contact />} />
      <Route path="*" element={<Error404 />} />
    </Route>
  ));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
