import Params from "../utils/params";
import flatenStrapiObject from '../utils/flatenStrapiObject';

export default class Teams {

    static async getTeams() {
        const results = await Params.strapi.find("teams", { 
            populate: {
              cover: "*",
              trainings: {
                fields: "*"
              }
            },
            pagination: {
              page: 1,
              pageSize: 10
            },
            sort: {
              team: "asc"
            }
        });
        const data = flatenStrapiObject(results.data);
        const meta = flatenStrapiObject(results.meta);
        return {data, meta};
    }

}