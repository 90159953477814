import Params from "../utils/params";
import flatenStrapiObject from '../utils/flatenStrapiObject';

export default class Sponsors {

    static async getSponsors() {
        const results = await Params.strapi.find("sponsors", { 
            populate: {
              logo: "*",
            },
            pagination: {
              page: 1,
              pageSize: 100
            },
            sort: {}
        });
        const data = flatenStrapiObject(results.data);
        const meta = flatenStrapiObject(results.meta);
        return {data, meta};
    }

}