import Params from "../utils/params";
import flatenStrapiObject from '../utils/flatenStrapiObject';
import {getDateFormatted} from '../utils/dates';

export default class News {

    static async getTrendNews(page, count) {
        const results = await Params.strapi.find("posts", { 
            filters: {
              event_date: {
                $gte: getDateFormatted()
              },
              is_important: true
            },
            populate: {
              cover: "*"
            },
            pagination: {
              page: page,
              pageSize: count
            },
            sort: {
              event_date: "desc"
            }
        });
        const data = flatenStrapiObject(results.data);
        const meta = flatenStrapiObject(results.meta);
        return {data, meta};
    }

    static async getNews(page, count) {
        const results = await Params.strapi.find("posts", { 
            filters: {
              event_date: {
                $gte: getDateFormatted()
              },
              is_important: false
            },
            populate: {
              cover: "*"
            },
            pagination: {
              page: page,
              pageSize: count
            },
            sort: {
              event_date: "asc"
            }
        });

        const data = flatenStrapiObject(results.data);
        const meta = flatenStrapiObject(results.meta);
        return {data, meta};
    }

    static async getPastNews(page, count) {
        const results = await Params.strapi.find("posts", { 
            filters: {
              event_date: {
                $lt: getDateFormatted()
              },
              is_important: false
            },
            populate: {
              cover: "*"
            },
            pagination: {
              page: page,
              pageSize: count
            },
            sort: {
              event_date: "desc"
            }
        });
        const data = flatenStrapiObject(results.data);
        const meta = flatenStrapiObject(results.meta);
        return {data, meta};
    }

}