import React, { useEffect, useState } from 'react';
import {default as TeamsApi} from '../api/Teams';
import '../styles/pages/teams.css';

import Team from '../components/Team';

const Teams = () => {
  const [teams, setTeams] = useState([]);
  const [teamsLoading, setTeamsLoading] = useState(true);

  const getTeams = async () => {
    const { data } = await TeamsApi.getTeams();
    setTeams(data);
    setTeamsLoading(false);
  }

  useEffect(() => {
    getTeams();
  }, [])

  return (
    <main>
        <div className='container'>
          <h1>Nos équipes</h1>
          { teamsLoading && <p>Chargement en cours</p> }
          <section className='teams'>
            { teams.map((t,i) => <Team key={i} team={t} />)}
          </section>
        </div>

    </main>
  )
}

export default Teams