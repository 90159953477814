import React, { useEffect, useState } from 'react';
import { Link,} from 'react-router-dom';
import {default as NewsApi} from '../api/News';

import '../styles/pages/home.css';

import hero from '../assets/images/hero.png';
import Article from '../components/Article';

const Home = () => {
  const [trend, setTrend] = useState([]);
  const [trendLoading, setTrendLoading] = useState(true);

  const [news, setNews] = useState([]);
  const [newsLoading, setNewsLoading] = useState(true);

  const getTrend = async () => {
    const { data } = await NewsApi.getTrendNews(1, 1);
    setTrend(data);
    setTrendLoading(false);
  }

  const getNews = async () => {
    const { data } = await NewsApi.getNews(1, 3);
    setNews(data);
    setNewsLoading(false);
  }

  useEffect(() => {
    getNews();
    getTrend();
  }, [])

  return (
    <main>
        <section className='hero' style={{ backgroundImage: `url(${hero})` }}></section>

        <div className='container'>
          <h1>Lunay Handball</h1>
          { trendLoading && <p>Chargement en cours</p> }
          { trend.length > 0 && 
            <section className='trending'>
              <h2>En ce moment</h2>
              { trend.map((n, i) => <Article key={i} news={n} /> )}
            </section>
          }

          <section className='news next'>
            <h2>A venir</h2>
            { newsLoading && <p>Chargement en cours</p> }
            { !newsLoading && news.length === 0 && <div>Aucune actualité à venir</div>}
            { news.map((n, i) => <Article key={i} news={n} /> )}
            
            <Link to="/news" className="more">Voir tous les articles</Link>
          </section>

        </div>

    </main>
  )
}

export default Home