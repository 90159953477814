import React, { useEffect } from 'react'

import '../styles/components/PageNav.css';

const PageNav = (props) => {
    const { current, max, setNewPage } = props;


    useEffect(() => {
      }, [current])

    return (

        <div className='pagination'>
            { Array.from(Array(max), (_, index) => index + 1).map(i => 
                <button key={i} onClick={ () => { setNewPage(i) } } className={current == i && "current"}>{i}</button>
            ) }
        </div>

    )
}

export default PageNav