import React from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import Params from '../utils/params';

import defaultTeam from '../assets/images/team.webp';

const Team = (props) => {

    const { team } = props;

    return (
        <article>
            <h2>{team.name}</h2>
            { team.cover && 
                <picture>
                    { team.cover.formats.small &&  <source srcSet={Params.API_URL+team.cover.formats.small.url} media="(width < 600px)"  /> }
                    { team.cover.formats.medium && <source srcSet={Params.API_URL+team.cover.formats.medium.url} media="(width < 800px)"  /> }
                    <img src={Params.API_URL+team.cover.url} alt="L'équipe" />
                </picture>
            }
            { !team.cover && <img src={defaultTeam} alt="l'équipe"/>}
            <div className='content'>
            <ReactMarkdown>{ team.content }</ReactMarkdown>

            <div className='planning'>
                <b>Entrainements</b>
                { team.trainings.length === 0 && <p>Aucun créneau renseigné</p>}
                { team.trainings.length > 0 &&
                    <ul>
                        { team.trainings.map((t, i) => {
                            const start = t.start_at.split(':')[0] + "h" + t.start_at.split(':')[1];
                            const end = t.end_at.split(':')[0] + "h" + t.end_at.split(':')[1];
                            return <li key={i}>{t.day} {start} - {end}</li>
                        })}
                    </ul>
                }
            </div>

            </div>

        </article>
    )
}

export default Team