import React, { useState } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import ModalImage, { Lightbox } from "react-modal-image";
import Params from '../utils/params';

const Article = (props) => {
    
    const {news} = props;

    const [isPictureModal, setIsPictureModal] = useState(false);
    const [pictureModal, setPictureModal] = useState(null);

    const displayPicture = () => {
        setIsPictureModal(true);
        setPictureModal(Params.API_URL+news.cover.url);
    }

    return (
        <article>
            { news.cover != null && 
                <picture onClick={ displayPicture }>
                    { news.cover.formats.small &&  <source srcSet={Params.API_URL+news.cover.formats.small.url} media="(width < 600px)"  /> }
                    { news.cover.formats.medium && <source srcSet={Params.API_URL+news.cover.formats.medium.url} media="(width < 800px)"  /> }
                    <img src={Params.API_URL+news.cover.url} alt="Illustration de l'article" />
                </picture>
            }

            <div className='content'>
            <ReactMarkdown linkTarget="_blank">{ news.content }</ReactMarkdown>
            </div>

            { isPictureModal && <Lightbox medium={pictureModal} onClose={() => setIsPictureModal(false)} />}
      </article>
    )
}

export default Article