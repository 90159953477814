import Params from "../utils/params";
import flatenStrapiObject from '../utils/flatenStrapiObject';

export default class Socials {

    static async getSocials() {
        const results = await Params.strapi.find("socials", { 
            populate: {
                cover: "*"
            },
            pagination: {
              page: 1,
              pageSize: 100
            },
            sort: {}
        });
        const data = flatenStrapiObject(results.data);
        const meta = flatenStrapiObject(results.meta);

        return {data, meta};
    }

}