import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import {default as NewsApi} from '../api/News';
import Article from '../components/Article';
import PageNav from '../components/PageNav';

const News = () => {
  const [trend, setTrend] = useState([]);
  const [trendLoading, setTrendLoading] = useState(true);
  const [trendPage, setTrendPage] = useState([]);

  const [news, setNews] = useState([]);
  const [newsLoading, setNewsLoading] = useState(true);
  const [newsPage, setNewsPage] = useState([]);
  const [newsCurrentPage, setNewsCurrentPage] = useState(1);

  const [pastNews, setPastNews] = useState([]);
  const [pastNewsLoading, setPastNewsLoading] = useState(true);
  const [pastNewsPage, setPastNewsPage] = useState([]);
  const [pastNewsCurrentPage, setPastNewsCurrentPage] = useState(1);

  const getTrend = async (page) => {
    const { data, meta } = await NewsApi.getTrendNews(page, 1);
    setTrend(data);
    setTrendLoading(false);
    setTrendPage(meta.pagination.pageCount);
  }

  const getNews = async () => {
    const { data, meta } = await NewsApi.getNews(newsCurrentPage, 3);
    setNews(data);
    setNewsLoading(false);
    setNewsPage(meta.pagination.pageCount);
  }

  const getPastNews = async () => {
    const { data, meta } = await NewsApi.getPastNews(pastNewsCurrentPage, 2);
    setPastNews(data);
    setPastNewsLoading(false);
    setPastNewsPage(meta.pagination.pageCount);
  }

  useEffect(() => {
    getTrend(1);
  }, [])

  useEffect(() => {
    getNews();
  }, [newsCurrentPage])

  useEffect(() => {
    getPastNews();
  }, [pastNewsCurrentPage])

  return (
    <main>
        <div className='container'>
          <h1>Actualités</h1>
          { trendLoading && <p>Chargement en cours</p> }
          { trend.length > 0 && 
            <section className='trending'>
              <h2>Important</h2>
              { trend.map((n, i) => <Article key={i} news={n} /> )}
            </section>
          }

          <section className='news next'>
            <h2>Actualités à venir</h2>
            { newsLoading && <p>Chargement en cours</p> }
            { !newsLoading && news.length === 0 && <div>Aucune actualité à venir</div>}
            { news.map((n, i) => <Article key={i} news={n} /> )}
            {newsPage > 1 && <PageNav current={newsCurrentPage} max={newsPage} url={setNewsCurrentPage} />}
          </section>

          <section className='news last'>
            <h2>Actualités passées</h2>
            { pastNewsLoading && <p>Chargement en cours</p> }
            { !pastNewsLoading && pastNews.length === 0 && <div>Aucune actualité passée</div>}
            { pastNews.map((n, i) => <Article key={i} news={n} /> )}
            {pastNewsPage > 1 && <PageNav current={pastNewsCurrentPage} max={pastNewsPage} setNewPage={setPastNewsCurrentPage} />}
          </section>
        </div>

    </main>
  )
}

export default News