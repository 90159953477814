import React from 'react'

import '../styles/components/footer.css';

const Footer = () => {
  return (
    <footer className='main-footer'>
      <p>Copyright 2023 &copy; lunay-handball.fr</p>
      <p className='small'>Réalisé par <a href="https://maximebranger.fr" target="_blank" rel="noreferrer">MaximeBranger</a> - 1.0.1</p>
    </footer>
  )
}

export default Footer