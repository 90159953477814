import React, { useEffect, useState } from 'react'
import {default as SponsorsApi} from '../api/Sponsors';

import '../styles/pages/sponsors.css';
import Sponsor from '../components/Sponsor';

const Sponsors = () => {
  const [sponsors, setSponsors] = useState([]);
  const [sponsorsLoading, setSponsorsLoading] = useState(true);

  const getSponsors = async () => {
    const { data } = await SponsorsApi.getSponsors();
    setSponsors(data);
    setSponsorsLoading(false);
  }

  useEffect(() => {
    getSponsors();
  }, [])

  return (
    <main>
        <div className='container'>
          <h1>Nos partenaires</h1>

          <section className='sponsors'>
            { sponsorsLoading && <p>Chargement en cours</p> }
            { sponsors.map((s,i) => <Sponsor key={i} sponsor={s} />)}
          </section>
        </div>

    </main>
  )
}

export default Sponsors