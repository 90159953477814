import React, { useEffect, useRef, useState } from 'react'
import {default as SponsorsApi} from '../api/Sponsors';
import Params from '../utils/params';

import '../styles/components/sponsor_slider.css';

const SponsorSlider = () => {

    const delay = 2500;

    const [index, setIndex] = useState(0);
    const timeoutRef = useRef(null);

    const [sponsors, setSponsors] = useState([]);
    const [sponsorsLoading, setSponsorsLoading] = useState(true);
  
    const getSponsors = async () => {
      const { data } = await SponsorsApi.getSponsors();
      setSponsors(data);
      setSponsorsLoading(false);
    }

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }
  
    useEffect(() => {
      getSponsors();
    }, [])

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
          () =>
            setIndex((prevIndex) =>
              prevIndex === sponsors.length - 1 ? 0 : prevIndex + 1
            ),
          delay
        );
    
        return () => {
          resetTimeout();
        };
      }, [index]);

    return (
        <div className="slideshow">
            <div className='slideshowSlider' style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
                { sponsors.map((sponsor,i) => 
                    <div key={i} className="slide">
                        <picture>
                            { sponsor.logo.formats.small &&  <source srcSet={Params.API_URL+sponsor.logo.formats.small.url} media="(width < 600px)"  /> }
                            { sponsor.logo.formats.medium && <source srcSet={Params.API_URL+sponsor.logo.formats.medium.url} media="(width < 800px)"  /> }
                            <img src={Params.API_URL+sponsor.logo.url} alt="Logo du sponsor"/>
                        </picture>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SponsorSlider