import Params from "../utils/params";
import flatenStrapiObject from '../utils/flatenStrapiObject';

export default class Contacts {

    static async getAddresses() {
        const results = await Params.strapi.find("information-addresses", { 
            populate: {
              address: "*"
            },
            pagination: {
              page: 1,
              pageSize: 100
            },
            sort: {}
        });
        const data = flatenStrapiObject(results.data);
        const meta = flatenStrapiObject(results.meta);

        return {data, meta};
    }

    static async getContacts() {
        const results = await Params.strapi.find("information-contacts", { 
            populate: {
              address: "*"
            },
            pagination: {
              page: 1,
              pageSize: 100
            },
            sort: {}
        });
        const data = flatenStrapiObject(results.data);
        const meta = flatenStrapiObject(results.meta);

        return {data, meta};
    }

}