import React, { useEffect, useState } from 'react'
import {default as ContactsApi} from '../api/Contacts';
import {default as SocialsApi} from '../api/Socials';
import {default as TeamsApi} from '../api/Teams';
import Params from '../utils/params';
import {formatTime} from '../utils/dates';

import '../styles/pages/contact.css';

const Contact = () => {
  const [addresses, setAddresses] = useState([]);
  const [addressesLoading, setAddressesLoading] = useState(true);

  const [contacts, setContacts] = useState([]);
  const [contactsLoading, setContactsLoading] = useState(true);

  const [socials, setSocials] = useState([]);
  const [socialsLoading, setSocialsLoading] = useState(true);

  const [teams, setTeams] = useState([]);
  const [teamsLoading, setTeamsLoading] = useState(true);

  const getAddresses = async () => {
    const { data } = await ContactsApi.getAddresses();
    setAddresses(data);
    setAddressesLoading(false);
  }

  const getTeams = async () => {
    const { data } = await TeamsApi.getTeams();
    setTeams(data);
    setTeamsLoading(false);
  }

  const getContacts = async () => {
    const { data } = await ContactsApi.getContacts();
    setContacts(data);
    setContactsLoading(false);
  }

  const getSocials = async () => {
    const { data } = await SocialsApi.getSocials();
    setSocials(data);
    setSocialsLoading(false);
  }

  useEffect(() => {
    getAddresses();
    getContacts();
    getSocials();
    getTeams();
  }, [])


  return (
    <main>
        <div className='container'>
          <h1>Informations Pratiques</h1>
          <section className='calendar'>
            <article>
              <h2>Horaires d'entrainements</h2>
              { teamsLoading && <p>Chargement en cours</p> }
              { teams.map((t, i) => 
                <article>
                  <h3>{ t.name }</h3> 
                  <p>
                    { t.trainings.map((tr, i) => 
                      <p>
                        { tr.day } { formatTime(tr.start_at) } - { formatTime(tr.end_at) }
                      </p>
                    ) }
                  </p>
                </article>
              )}
            </article>
          </section>
          <section className='contact'>
            { addressesLoading && <p>Chargement en cours</p> }
            { addresses.map((a, i) => 
              <article key={i}>
                <h2>{ a.title }</h2>
                <address>
                { a.name }
                  <br/>
                  { a.address.street }
                  <br/>
                  { a.address.post_code } { a.address.city }
                </address>
              </article>
            )}

            { contactsLoading && <p>Chargement en cours</p> }
            { contacts.map((a, i) => 
              <article key={i}>
                <h2>{ a.title }</h2>
                <address>
                  { a.name }
                  { a.address && 
                    <p>
                      { a.address?.street }
                      <br/>
                      { a.address?.post_code } { a.address?.city }
                    </p>
                  }

                  { a.phone } 
                  <br/>
                  { a.email && <a href={`mailto:${a.email}`}>Envoyer un email</a>}
                </address>
              </article>
            )}

            <article>
              <h2>Nos réseaux sociaux</h2>
              { socialsLoading && <p>Chargement en cours</p> }
              <div className='icons'>
                { socials.map((s,i) => 
                  <a key={i} href={s.url} target='_blank' rel="noreferrer">
                    <img src={Params.API_URL+s.cover.url} alt={s.title}/>
                  </a>
                )}

              </div>
            </article>

          </section>
        </div>

    </main>
  )
}

export default Contact