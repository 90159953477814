function getDateFormatted() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

function formatTime(time) {
    const datas = time.split(':');

    return datas[0] + "h" + datas[1];
}

export { getDateFormatted, formatTime };